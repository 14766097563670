<template>
    <div id="fiche-mission-page" class="pt-3 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack">
                <i class="fa fa-chevron-left"></i> retour
            </b-button>
            Clients
            <b-button id="modeList" @click="changeMode"><span v-if="listMode === 'all'">Autour de moi</span><span
                v-else>Tous</span></b-button>
        </div>
        <div id="list">
            <div id="listClients">
                <div v-if="listMode === 'all'">
                    <div v-for="categorie in categories" :key="categorie.letter">
                        <h6 class="text-center text-white">{{ categorie.letter }}</h6>
                        <b-list-group class="mb-4">
                            <div v-for="client in categorie.clients" :key="client.intervention_idadress">
                                <div @click="selectClient(client)"
                                     class="list-group-item list-group-item-action border-left-0 border-top-0 border-right-0">
                                    <div class="centered-ico col-2 bg-info-corporate text-white"><i
                                        class="fa-2x fas fa-user" aria-hidden="true"></i></div>
                                    <div class="align-items-center row text-dark">
                                        <div class="col-8 offset-2">
                                            <span class="d-block text-truncate"> {{
                                                    getFormattedTitle(client.customer_title)
                                                }} {{
                                                    getFormattedFirstname(client.customer_firstname)
                                                }} {{ client.customer_lastname }}</span>
                                          <div style="font-size: 10px;" class="text-muted">({{ client.adress1 }} {{ client.postal_code }}, {{ client.city }})</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-list-group>
                    </div>
                </div>
                <div v-else>
                    <h6 class="text-center text-white">Vos clients classés par proximité</h6>
                    <b-list-group class="mb-4">
                        <div v-for="client in clients" :key="client.intervention_idadress">
                            <div @click="selectClient(client)"
                                 class="list-group-item list-group-item-action border-left-0 border-top-0 border-right-0">
                                <div class="centered-ico col-2 bg-info-corporate text-white"><i
                                    class="fa-2x fas fa-user" aria-hidden="true"></i></div>
                                <div class="align-items-center row text-dark">
                                    <div class="col-8 offset-2">
                                        <span class="d-block text-truncate">{{
                                                getFormattedTitle(client.customer_title)
                                            }} {{
                                                getFormattedFirstname(client.customer_firstname)
                                            }} {{ client.customer_lastname }}</span>
                                      <div style="font-size: 10px;" class="text-muted">({{ client.adress1 }} {{ client.postal_code }}, {{ client.city }})</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-list-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router'
import clientService from "../../services/clientService";

export default {
    name: "Clients",
    mounted() {
        this.$emit('menu', 'clients')
        if (router.currentRoute.query.selectMode && router.currentRoute.query.selectMode === 'detection') {
            this.selectMode = 'detection'
        }
        this.getAllClients()
    },
    data() {
        return {
            categories: [],
            clients: [],
            listMode: 'all',
            selectMode: 'standard'
        }
    },
    methods: {
        getBack() {
            router.go(-1)
        },
        changeMode() {
            this.listMode = (this.listMode === 'all') ? 'gps' : 'all'
            if (this.listMode === 'all') {
                this.getAllClients()
            } else {
                this.getClientsAround()
            }
        },
        getAllClients() {
            clientService.getClients(null).then((response) => {
                if (response.data.etat === 'OK') {
                    if (response.data.data) {
                        this.categories = []
                        for (let client of response.data.data) {
                            let categorie = this.categories.find(i => i.letter === client.customer_lastname.charAt(0).toUpperCase())
                            if (categorie === undefined) {
                                this.categories.push({
                                    letter: client.customer_lastname.charAt(0).toUpperCase(),
                                    clients: []
                                })
                                categorie = this.categories.find(i => i.letter === client.customer_lastname.charAt(0).toUpperCase())
                            }
                            categorie.clients.push(client)
                            categorie.clients.sort((a, b) => {
                                if (a.customer_lastname > b.customer_lastname) {
                                    return 1
                                }
                                if (a.customer_lastname < b.customer_lastname) {
                                    return -1
                                }
                                return 0
                            })
                        }
                        this.categories.sort((a, b) => {
                            if (a.letter > b.letter) {
                                return 1
                            }
                            if (a.letter < b.letter) {
                                return -1
                            }
                            return 0
                        })
                    }
                } else {
                    this.$rollbar.error('Erreur 3019');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3019',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3020', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3020',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        },
        getClientsAround() {
            let params = {
                latitude: 0,
                longitude: 0
            }
            navigator.geolocation.getCurrentPosition((pos) => {
                params.latitude = pos.coords.latitude
                params.longitude = pos.coords.longitude
                clientService.getClients(params).then((response) => {
                    if (response.data.etat === 'OK') {
                        this.clients = response.data.data
                    } else {
                        this.$rollbar.error('Erreur 3021');
                        this.$bvToast.toast('Une erreur est survenue', {
                            title: 'Erreur 3021',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                }).catch((error) => {
                    this.$rollbar.error('Erreur 3022', error);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3022',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                })
            }, (err) => {
                console.log(err)
            })
        },
        selectClient(client) {
            if (this.selectMode === 'standard') {
                router.push({name: 'fiche-client', params: {clientId: client.customer_idcustomer, intervAdress: client.intervention_idadress}})
            } else {
                router.push('/detection?clientId=' + client.customer_idcustomer + '&intervadress=' + client.intervention_idadress)
            }
        },
        getFormattedFirstname(firstname) {
            if (firstname === null)
                return ''

            return firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase()
        },
        getFormattedTitle(title) {
            if (!title || title === 'null') return ''
            return title + '.'
        }
    }
}
</script>

<style scoped>
#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus {
    box-shadow: none;
}

#list {
    margin-top: 3.5rem;
}

.declarationPrestation {
    border: 1px solid #b70e0c;
    color: #b70e0c;
}

#modeList {
    max-width: 130px;
}
</style>
