import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    getClients (params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/customers'
        if (params !== null) {
            url += '?latitude=' + params.latitude + '&longitude=' + params.longitude
        }
        return window.axios.get(url, config)
    },
    getClient (clientId, addressId = null) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/customers/' + clientId
        if (addressId) {
            url += '?intervadress=' + addressId
        }
        return window.axios.get(url, config)
    },
    getClientContractsByEmployeeContract (idEmployeeContract, clientId) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + '/api-m/einstein/customers/contrat/association-employeecontract?idemployee_contract=' + idEmployeeContract + '&idcustomer=' + clientId
        return window.axios.get(url, config)
    },
    getContracts (clientId, addressId = null) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/customers/contrat/from-id-customer/' + clientId
        if (addressId) {
            url += '?intervadress=' + addressId
        }
        return window.axios.get(url, config)
    }
    // api-m/einstein/planning/prochaine-intervention
}
